<template>
  <div class="tool-tips-group" :style="{width:isFlex?'100%':'auto'}">
    <div v-if="!isLeft" :class="{'flex-1':isFlex}">
      <slot />
    </div>
    <ElPopover
      effect="light"
      placement="top"
      trigger="hover"
    >
      <div v-if="tipsContent" class="tips-content" v-html="tipsContent"></div>
      <slot name="content">
      </slot>
      <ElButton  slot="reference" class="ques-btn" tabindex="-1" type="text" size="large" icon="el-icon-question"
                :style="{'margin-left': isLeft?0:'6px','margin-right': isLeft?'6px':0}" />
    </ElPopover>
    <div v-if="isLeft" :class="{'flex-1':isFlex}">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'toolTipsGroup',
  props: {
    tipsContent: String,
    isFlex: {
      type: Boolean,
      default: false
    },
    isLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.tool-tips-group {
  @include flex;

  .ques-btn {
    padding: 0;
    font-size: 18px;
    color: var(--color-warning);

    &:hover {
      opacity: .8;
      color: var(--color-warning);
    }

    &:visited, &:focus {
      opacity: .9;
      color: var(--color-warning);
    }
  }

}

.el-tooltip__popper {
  .tips-content {
    font-size: 14px;
    line-height: 1.6;
  }
}
</style>
