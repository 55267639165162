/**
 * 密码校验 支持特殊符号/大小写
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validatePassword = (rule, value, callback) => {
  if (value && !/^[A-Za-z0-9]+$/.test(value)) {
    return callback(new Error('格式错误,仅支持英文和数字'))
  } else if (value.length < 6) {
    return callback(new Error('不能低于6位'))
  } else {
    return callback()
  }
}

/**
 * 数字校验
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validateNumber = (rule, value, callback) => {
  if (value && !/^\d+$/.test(value)) {
    return callback(new Error('仅支持纯数字或正整数!'))
  } else {
    return callback()
  }
}

/**
 * 只能正数,整数或者保留两位小数校验
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validateTwoDecimalPlaces = (rule, value, callback) => {
  if (value) {
    if (!/^\d+(?:\.\d{1,2})?$/.test(value)) {
      return callback(new Error('仅支持正整数及保留两位小数'))
    } else if (Number(value) > 99999.99) {
      return callback(new Error('仅支持小于99999.99的数字'))
    } else {
      return callback()
    }
  } else {
    return callback()
  }
}
/**
 * 只能正数,整数或者保留两位小数校验
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validateTwoDecimalPlacesRequired = (rule, value, callback) => {
  if (value) {
    if (!/^\d+(?:\.\d{1,2})?$/.test(value)) {
      return callback(new Error('仅支持正整数及保留两位小数'))
    } else if (Number(value) > 99999.99) {
      return callback(new Error('仅支持小于99999.99的数字'))
    } else {
      return callback()
    }
  } else {
    return callback(new Error('该项为必填！'))
  }
}

/**
 * 支持负数,整数或者保留两位小数校验
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validateNegativeNumberWithTwoDecimalPlaces = (rule, value, callback) => {
  if (value) {
    if (!/^-?\d+(?:\.\d{1,2})?$/.test(value)) {
      return callback(new Error('仅支持数字及保留两位小数'))
    } else if (Number(value) > 99999.99 || Number(value) < -99999) {
      return callback(new Error('仅支持-99999~99999.99之间的数字'))
    } else {
      return callback()
    }
  } else {
    return callback()
  }
}

/**
 * 数字和英文校验
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validateNumEng = (rule, value, callback) => {
  if (value && !/^[A-Za-z0-9]+$/.test(value)) {
    return callback(new Error('仅支持英文和数字'))
  } else {
    return callback()
  }
}

/**
 * 电子邮箱校验
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validateEmail = (rule, value, callback) => {
  if (value && !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
    return callback(new Error('invalid'))
  } else {
    return callback()
  }
}

/**
 * 手机号校验
 * @param rule
 * @param value
 * @param callback 3678
 * @returns {Promise<never>|Promise<void>}
 */
export const validatePhone = (rule, value, callback) => {
  if (value && !/^1[3456789]\d{9}$/.test(value)) {
    return callback(new Error('电话号码格式错误'))
  } else if (value && String(value).length !== 11) {
    return callback(new Error('联系方式必须为11位！'))
  } else {
    callback()
  }
}

/**
 * 身份证校验
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validateIdNumber = (rule, value, callback) => {
  const _IDRe18 = /^([1-9][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  const _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
  if (value && !_IDRe18.test(value) && !_IDre15.test(value)) {
    return callback(new Error('身份证格式错误'))
  } else {
    callback()
  }
}

/**
 * 字数校验,限制30字
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validate30WordLimit = (rule, value, callback) => {
  if (value && value.length > 30) {
    return callback(new Error('仅限30字'))
  } else {
    return callback()
  }
}
/**
 * 字数校验,限制50字
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validate50WordLimit = (rule, value, callback) => {
  if (value && value.length > 50) {
    return callback(new Error('仅限30字'))
  } else {
    return callback()
  }
}
/**
 * 字数校验,限制20字
 * @param rule
 * @param value
 * @param callback
 * @returns {Promise<never>|Promise<void>}
 */
export const validate20WordLimit = (rule, value, callback) => {
  if (value && value.length > 20) {
    return callback(new Error('仅限20字'))
  } else {
    return callback()
  }
}

// 限制30字的validateObj
export const limit30WordsObj = { validator: validate30WordLimit, trigger: 'blur' }
