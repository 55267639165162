import request from '@/service/request'

// 获取学区列表
const getCampusList = (params) => {
  return request.post('/sch/campus/page', params)
}

// 添加学区
const addCampus = (params) => {
  return request.post('/sch/campus/save', params)
}

// 删除学区
const deleteCampus = (params) => {
  return request.post('/sch/campus/del/' + params)
}

// 根据id获取学区信息
const getCampusById = (params) => {
  return request.post('/sch/campus/getById/' + params)
}

/* 获取校区list
* @params {number} offFlag 状态 0-启用 1-禁用
* */
const getCampusListQuery = (params = {}) => {
  return request.post('/sch/campus/list', params)
}

export {
  getCampusList,
  addCampus,
  deleteCampus,
  getCampusById,
  getCampusListQuery
}
