/* 教务处 */
export const educationRouter = [
  {
    path: '/education/student',
    name: 'student',
    component: () => import('@/views/pages/studentManage/student.vue'),
    meta: {
      title: '学籍管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/education/majorTransfer',
    name: 'majorTransfer',
    component: () => import('@/views/pages/studentManage/major-transfer/majorTransfer.vue'),
    meta: {
      title: '转专业名单',
      role: ['超级管理员']
    }
  },
  {
    path: '/education/batchProcessing',
    name: 'batchProcessing',
    component: () => import('@/views/pages/studentManage/batch-processing/batchProcessing.vue'),
    meta: {
      title: '一键操作',
      role: ['超级管理员']
    }
  },
  // 模块迁移，教学楼 模块迁移至教务处
  {
    path: '/education/teachBuild',
    name: 'teachBuild',
    component: () => import('@/views/pages/teachBuildManage/teachBuild.vue'),
    meta: {
      title: '教学楼列表',
      role: ['超级管理员']
    }
  },
  {
    path: '/education/teachLock',
    name: 'teachLock',
    component: () => import('@/views/pages/teachBuildManage/classroom-lock/teachLock.vue'),
    meta: {
      title: '教室门锁绑定',
      role: ['超级管理员']
    }
  },
  {
    path: '/education/classRoom',
    name: 'classRoom',
    component: () => import('@/views/pages/teachBuildManage/classRoom.vue'),
    meta: {
      title: '教室管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/education/classRoomApplicate',
    name: 'classRoomApplicate',
    component: () => import('@/views/pages/teachBuildManage/classRoomApplicate.vue'),
    meta: {
      title: '教室管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/psychologySchedule',
    name: 'psychologySchedule',
    component: () => import('@/views/pages/studentManage/psychology-schedule/psychologySchedule.vue'),
    meta: {
      title: '心理咨询排班',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/psychologyRoom',
    name: 'psychologyRoom',
    component: () => import('@/views/pages/studentManage/psychology-room/psychologyRoom.vue'),
    meta: {
      title: '心理咨询室',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/psychologyTeacher',
    name: 'psychologyTeacher',
    component: () => import('@/views/pages/studentManage/psychology-teacher/psychologyTeacher.vue'),
    meta: {
      title: '心理咨询教师',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/psychoStuRecord',
    name: 'psychologyStuRecord',
    component: () => import('@/views/pages/studentManage/psychology-stu-record/psychologyStuRecord.vue'),
    meta: {
      title: '心理咨询记录',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/badmintonCourt',
    name: 'badmintonCourt',
    component: () => import('@/views/pages/studentManage/badminton-court/badmintonCourt.vue'),
    meta: {
      title: '羽毛球场地管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/badmintonOrderRecord',
    name: 'badmintonOrderRecord',
    component: () => import('@/views/pages/studentManage/badminton-order-record/badmintonOrderRecord.vue'),
    meta: {
      title: '场地预约记录',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/badmintonBlockList',
    name: 'badmintonBlockList',
    component: () => import('@/views/pages/studentManage/badminton-block-list/badmintonBlockList.vue'),
    meta: {
      title: '场地黑名单',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/badmintonSchedule',
    name: 'badmintonSchedule',
    component: () => import('@/views/pages/studentManage/badminton-schedule/badmintonSchedule.vue'),
    meta: {
      title: '场地排班',
      role: ['超级管理员']
    }
  },

  {
    path: '/studentDepartment/gymCourt',
    name: 'gymCourt',
    component: () => import('@/views/pages/studentManage/gym-court/GymCourt.vue'),
    meta: {
      title: '健身房管理',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/gymSchedule',
    name: 'GymSchedule',
    component: () => import('@/views/pages/studentManage/gym-schedule/GymSchedule.vue'),
    meta: {
      title: '健身房排期',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/gymOrderRecord',
    name: 'GymOrderRecord',
    component: () => import('@/views/pages/studentManage/gym-order-record/GymOrderRecord.vue'),
    meta: {
      title: '健身房预约记录',
      role: ['超级管理员']
    }
  },
  {
    path: '/studentDepartment/gymBlockList',
    name: 'GymBlockList',
    component: () => import('@/views/pages/studentManage/gym-block-list/GymBlockList.vue'),
    meta: {
      title: '健身房预约记录',
      role: ['超级管理员']
    }
  }
]
