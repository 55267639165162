<template>
  <el-form class="view-search-form" ref="formRef" :rules="rules" :model="queryInfo" :label-width="labelWidth" inline
           @submit.prevent>
    <slot name="pre-form"></slot>
    <el-form-item label="全日制专业" label-width="110" v-if="isIncludes('partTimeFlag')">
      <el-select v-model="queryInfo.partTimeFlag" size="small" @change="handlePartTimeFlag">
        <el-option :value="0" label="全日制" />
        <el-option :value="1" label="非全日制" />
      </el-select>
    </el-form-item>
    <el-form-item label="年级" prop="grade" label-width="55px" v-if="isIncludes('grade')">
      <el-select v-model="queryInfo.grade" :clearable="isGradeClearable" filterable @change="handleSearch('grade')"
                 size="small" style="width:100px;">
        <el-option v-for="{ paramValue, id } in gradeList" :key="id" :label="paramValue" :value="Number(paramValue)" />
      </el-select>
    </el-form-item>
    <el-form-item label="学年" v-if="isIncludes('year')" label-width="55px">
      <el-select v-model="queryInfo.year" size="small" clearable>
        <el-option v-for="num in 3" :key="num" :value="num" :label="`第${num}学年`" />
      </el-select>
    </el-form-item>
    <el-form-item label="学年" v-if="isIncludes('gradeName')" label-width="55px">
      <el-select v-model="queryInfo.gradeName" size="small" clearable placeholder="请选择">
        <el-option v-for="item in gradeNameList" :key="item.id" :label="item.gradeName" :value="item.gradeName" />
      </el-select>
    </el-form-item>
    <el-form-item label="学年" v-if="isIncludes('schoolYear')" label-width="55px">
      <el-select v-model="queryInfo.schoolYear" size="small" clearable placeholder="请选择">
        <el-option v-for="item in gradeNameList" :key="item.id" :label="item.gradeName" :value="item.gradeName" />
      </el-select>
    </el-form-item>
    <el-form-item label="学期" v-if="isIncludes('term')" label-width="55px">
      <el-select v-model="queryInfo.term" size="small" clearable placeholder="请选择">
        <el-option v-for="{ paramValue, id } in termList" :key="id" :label="`第${paramValue}学期`" :value="paramValue">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="校区" v-if="isIncludes('campus')" label-width="55px">
      <el-select ref="campusRef" v-model="queryInfo.campusId" clearable filterable @change="handleSearch('campus')"
                 size="small">
        <el-option v-for="{ campusName, id } in campusList" :key="id" :label="campusName" :value="id" />
      </el-select>
    </el-form-item>
    <el-form-item label="部门" v-if="isIncludes('department')" label-width="60">
      <el-select v-model="queryInfo.deptId" clearable filterable @change="handleSearch('department')" size="small">
        <el-option v-for="(item, index) in departmentList" :key="index" :label="item.deptName" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="院系" label-width="60" v-if="isIncludes('college')">
      <el-select ref="collegeRef" v-model="queryInfo.collegeId" clearable filterable @change="handleSearch('college')"
                 size="small" :disabled="disabledObj.college">
        <el-option v-for="{ collegeName, id } in collegeList" :key="id" :label="collegeName" :value="id" />
      </el-select>
    </el-form-item>
    <el-form-item label="专业" prop="majorId" label-width="60" v-if="isIncludes('major')">
      <el-select ref="majorRef" v-model="queryInfo.majorId" clearable filterable @change="handleSearch('major')"
                 size="small">
        <el-option v-for="(item, index) in majorList" :key="index" :label="item.majorName" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="班级" label-width="60" v-if="isIncludes('class')">
      <el-select ref="classRef" v-model="queryInfo.classesId" clearable filterable @change="handleSearch('class')"
                 size="small">
        <el-option v-for="(item, index) in classList" :key="index" :label="item.className" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-form-item label="是否有班级" label-width="80" v-if="isIncludes('classesNoNullFlag')">
      <el-select v-model="queryInfo.classesNoNullFlag" size="small" clearable>
        <el-option :value="0" label="否" />
        <el-option :value="1" label="是" />
      </el-select>
    </el-form-item>
    <el-form-item label="性别" v-if="isIncludes('sexType')" label-width="55px">
      <el-select v-model="queryInfo.sexType" size="small" clearable style="width:100px;">
        <el-option :value="1" label="男" />
        <el-option :value="2" label="女" />
      </el-select>
    </el-form-item>
    <el-form-item label="学籍状态" label-width="72" v-if="isIncludes('stuStatus')">
      <el-select ref="stuStatusRef" v-model="queryInfo.stuStatus" clearable filterable
                 @change="handleSearch('stuStatus')"
                 size="small" style="width:140px;">
        <el-option v-for="{ paramName, paramValue } in stuStatusList" :key="paramValue" :label="paramName"
                   :value="Number(paramValue)" />
      </el-select>
    </el-form-item>
    <slot />
    <el-form-item prop="keyword" label="关键字" label-width="64" v-if="isIncludes('keyword')">
      <el-input v-model.trim="queryInfo.keyword" size="small" clearable :placeholder="`请输入${keywordPlaceholder}`"
                :style="{ width: keywordWidth }" @clear="handleKeyword($event)"
                @keyup.enter.native="handleKeyword($event)" />
    </el-form-item>
    <el-form-item style="margin-right: 0;">
      <el-button size="small" v-if="showSearchBtn" icon="el-icon-search" clearable @click.stop="search">查询
      </el-button>
      <el-button size="small" v-if="showClearBtn" @click="clear">重置</el-button>
    </el-form-item>
    <div>
    </div>
  </el-form>
</template>

<script>
import store from '@/store/index'
import { cloneDeep, throttle } from 'lodash'

import { pageGrade } from '@/api/grade.js'
import { getParamsList } from '@/api/sysParams.js'

export default {
  /**
   * @description 用于头部下拉过滤选择
   * @property {Array} toolList 显示选择器
   * @property {Array} insertSelectAll 是否插入 '全选(所有)' 选项
   * @event change 选择事件
   */
  name: 'viewSearchForm',
  props: {
    labelWidth: { // label 宽度
      type: String,
      default: '80px'
    },
    queryInfo: {
      type: Object,
      default: () => ({
        loadFlag: false // loadFlag=true 进入模块时不自动请求table数据
      })
    },
    toolList: { // 模块需要加载的工具条
      type: Array,
      default: () => ([])
    },
    disabledTools: { // 禁用的选项,跟 disabledObj 搭配使用
      type: Array,
      default: () => (['college'])
    },
    insertSelectAll: { // 插入 一个'所有xx'选项 的select
      type: Array,
      default: () => (['campus'])
    },
    keywordWidth: { // 关键字搜索 宽度
      type: String,
      default: '220px'
    },
    showSearchBtn: { // 显示搜索按钮
      type: Boolean,
      default: true
    },
    showClearBtn: { // 显示重置按钮
      type: Boolean,
      default: true
    },
    selectStustatus: {
      type: Number,
      default: 1 // 在读
    },
    filterStuStatusList: { // 要显示的stuStatus 选项
      type: Array,
      default: () => ([])
    },
    isGradeClearable: { // 年级是否可清空
      type: Boolean,
      default: true
    },
    keywordPlaceholder: { // 搜索关键字的placeholder
      type: String,
      default: '请输入关键字'
    },
    filterKeys: { // 关键字搜索时维持默认值,不设null的字段
      type: Array,
      default: () => ([])
    },
    rules: { // 必填，用于一键操作模块
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      initQuery: {}, // 保存初始的query,用于重置
      formNames: { // 批量操作时,获取品类名
        campusName: '',
        collegeName: '',
        majorName: '',
        classesName: '',
        grade: '',
        stuStatusName: ''
      },
      campusList: [],
      gradeList: this.$store.state.systemParam.grade, // 年级list
      gradeNameList: [], // 学年list
      termList: [], // 学期list
      departmentList: [], // 宿舍 list
      collegeList: [], // 院系 list
      majorList: [], // 专业 list
      classList: [], // 班级 list
      stuStatusList: this.$store.state.systemParam.stuStatus, // 学籍状态 list
      disabledObj: { // 选项的disabled状态,跟 disabledTools 搭配使用
        college: false
      }
    }
  },
  mounted() {
    this.searchInit()
  },
  methods: {
    // 按需加载list, 以及请求table数据
    async searchInit() {
      const _promises = []
      if (this.isIncludes('grade')) _promises.push(this.getGradeList)
      if (this.isIncludes('campus')) _promises.push(this.getCampusList)
      if (this.isIncludes('gradeName')) _promises.push(this.getGradeNameList)
      if (this.isIncludes('schoolYear')) _promises.push(this.getGradeNameList)
      if (this.isIncludes('term')) _promises.push(this.getTermList)
      if (this.isIncludes('department')) _promises.push(this.getDepartment)
      if (this.isIncludes('college')) {
        _promises.push(this.getCollegeList)
        // 判断用户是否已分配院系,已分配院系后, college禁选
        const collegeId = this.$store.state.login.userInfo.collegeId
        if (this.disabledIncludes('college') && collegeId) {
          this.queryInfo.collegeId = collegeId
          this.disabledObj.college = true
          // 如果 该用户已经分配了collegeId，需要根据userInfo中的collegeId获取majorList
          if (this.toolList.includes('major')) {
            _promises.push(this.getMajorList)
          }
        }
      } else {
        // 专业, 存在college的选项时,初始化不加载majorList
        if (this.toolList.includes('major')) _promises.push(this.getMajorList)
      }
      // 学籍list
      if (this.toolList.includes('stuStatus')) _promises.push(this.getStuStatus)
      await Promise.all(_promises.map(func => func()))
      this.setInitQuery()
      // this.queryInfo.loadFlag===true 时,默认不加载数据
      if (!this.queryInfo.loadFlag) {
        this.$emit('on-search')
      }
    },
    async handleSearch(type) {
      await this.$nextTick()
      switch (type) {
        case 'class':
          // 判断是否需要获取 选中的class信息
          if (this.queryInfo.classesId) {
            this.formNames.classesName = this.$refs.classRef.selectedLabel || ''
            // 存在classesId时 清空grade,防干扰数据
            // if (this.queryInfo.grade) this.queryInfo.grade = null
            if (this.queryInfo.classInfo) {
              this.queryInfo.classInfo = this.classList.filter(item => item.id === this.queryInfo.classesId)[0]
            }
          } else {
            if (this.queryInfo.classInfo) this.queryInfo.classInfo = {}
          }
          break
        case 'major':
          this.formNames.majorName = this.$refs.majorRef.selectedLabel
          if (this.classList.length) this.classList = []
          if (this.isIncludes('class')) {
            if (this.queryInfo.classesId) this.queryInfo.classesId = null
            await this.getClassList()
          }
          break
        case 'college':
          if (this.majorList.length) this.majorList = []
          if (this.classList.length) this.classList = []
          if (this.queryInfo.majorId) this.queryInfo.majorId = null
          if (this.queryInfo.classesId) this.queryInfo.classesId = null
          if (this.queryInfo.deptId && this.queryInfo.collegeId) {
            this.queryInfo.deptId = null
          }
          this.formNames.collegeName = this.$refs.collegeRef.selectedLabel || ''
          await this.getMajorList()
          break
        case 'campus':
          this.formNames.campusName = this.$refs.campusRef.selectedLabel || ''
          if (this.isIncludes('college')) {
            if (this.majorList.length) this.majorList = []
            if (this.classList.length) this.classList = []
            this.queryInfo.collegeId = null
            this.queryInfo.majorId = null
            this.queryInfo.classesId = null
            await this.getCollegeList()
          }
          break
        case 'grade':
          // if (this.queryInfo.keyword) {
          //   this.queryInfo.keyword = ''
          // }
          if (this.queryInfo.grade) {
            this.formNames.grade = `${this.queryInfo.grade}级`
          } else if (this.queryInfo.grade === '') {
            this.queryInfo.grade = null
          }
          // 只要存在学院id, 都需要重新请求班级list
          if (this.isIncludes('major')) {
            this.queryInfo.majorId = null
            this.majorList = []
            if (this.queryInfo.collegeId) {
              await this.getMajorList()
            }
          }
          // 只要存在专业id, 都需要重新请求班级list
          if (this.isIncludes('class')) {
            this.queryInfo.classesId = null
            this.classList = []
            if (this.queryInfo.majorId) {
              await this.getClassList()
            }
          }
          this.search()
          break
        case 'stuStatus':
          if (this.queryInfo.stuStatus) {
            this.formNames.stuStatusName = this.$refs.stuStatusRef.selectedLabel
          } else if (this.queryInfo.stuStatus === '') {
            this.queryInfo.stuStatus = null
          }
          break
        case 'department':
          if (this.queryInfo.deptId && this.queryInfo.collegeId) {
            this.queryInfo.collegeId = null
          }
          break
      }
      this.$emit('update:formLabels', this.formNames)
    },
    /*
    * 清空除keyword外的项目
    * val 关键字
    * filterKeys 不需要重置的字段 默认为页码跟pageSize
     */
    clearExceptKeyword(val) {
      if (val) {
        // _keyList{Array} 需要保留原值,不能清空 的字段
        const _keyList = ['pageSize', 'pageNum', 'keyword', 'grade', ...this.filterKeys]
        if (this.isIncludes('college') && this.$store.state.login.userInfo.collegeId) {
          // 如果该账号分配了 管辖学院，不清空collegeId
          _keyList.push('collegeId')
        }
        // 需要把关键字/pageNum/pageSize以外的字段都设为null再搜索
        Object.keys(this.queryInfo).forEach(key => {
          if (!_keyList.includes(key)) {
            if (this.queryInfo[key]) {
              // 数值 不能为null,要设 []
              if (Array.isArray(this.queryInfo[key])) {
                this.queryInfo[key] = []
              } else if (this.isObject(this.queryInfo[key])) {
                this.queryInfo[key] = {}
              } else {
                this.queryInfo[key] = null
              }
            } else if (this.queryInfo[key] === '' || this.queryInfo[key] === 0) {
              this.queryInfo[key] = null
            }
          }
        })
      }
    },
    /*
    * 关键字 搜索
    * val 关键字
    * filterKeys 不需要重置的字段 默认为keyword,页码跟pageSize
     */
    handleKeyword(val) {
      if (val) {
        this.search()
      } else {
        this.clear()
      }
    },
    // 获取校区 list
    getCampusList() {
      this.$http.getCampusListQuery().then((res) => {
        if (this.insertSelectAll.includes('campus')) {
          this.campusList = [
            {
              id: '',
              campusName: '所有校区'
            },
            ...res.data
          ]
        } else {
          this.campusList = res.data
        }
      })
    },
    // 获取部门
    getDepartment() {
      this.$http.getDepartmentListQuery({}).then((res) => {
        if (this.insertSelectAll.includes('department')) {
          this.departmentList = [
            {
              id: '',
              deptName: '所有部门'
            },
            ...res.data
          ]
        } else {
          this.departmentList = res.data
        }
      })
    },
    // 获取学年--gradeNameList
    getGradeNameList() {
      pageGrade({
        pageNum: 1,
        pageSize: 999,
        keyword: ''
      }).then((res) => {
        this.gradeNameList = res.data.list
      })
    },
    // 获取学期--termList
    getTermList() {
      getParamsList({ keyword: 'term' }).then((res) => {
        this.termList = res.data.list
      })
    },
    // 获取院系
    getCollegeList() {
      this.$http
        .getCollegeListQuery({
          campusId: this.queryInfo.campusId
        })
        .then((res) => {
          if (this.insertSelectAll.includes('college')) {
            this.collegeList = [
              {
                id: '',
                collegeName: '所有系部'
              },
              ...res.data
            ]
          } else {
            this.collegeList = res.data
          }
        })
    },
    // 获取专业
    getMajorList(partTimeFlag) {
      // 如果存在 collegeList,则必须存在collegeId才能加载majorList
      if (this.collegeList.length && !this.queryInfo.collegeId) return
      this.$http
        .getMajorListQuery({
          collegeId: this.queryInfo.collegeId,
          grade: this.queryInfo.grade,
          partTimeFlag: partTimeFlag
        })
        .then((res) => {
          if (this.insertSelectAll.includes('major')) {
            this.majorList = [
              {
                id: '',
                majorName: '所有专业'
              },
              ...res.data
            ]
          } else {
            this.majorList = res.data
          }
        })
    },
    // 获取班级
    getClassList() {
      this.$http
        .getClassListQuery({
          majorId: this.queryInfo.majorId,
          grade: this.queryInfo.grade
        })
        .then((res) => {
          if (this.insertSelectAll.includes('class')) {
            this.majorList = [
              {
                id: '',
                majorName: '所有班级'
              },
              ...res.data
            ]
          } else {
            this.classList = res.data
          }
        })
    },
    // 获取年级
    async getGradeList() {
      if (!this.$store.state.systemParam.grade.length) {
        await store.dispatch('systemParam/getSystemDictionary', 'grade')
      }
      let _grade = store.state.systemParam.grade
      if (this.insertSelectAll.includes('grade')) {
        _grade = [{ paramValue: '所有年级' }, ..._grade]
      }
      this.gradeList = _grade
    },
    // 获取学籍状态
    async getStuStatus() {
      if (!this.$store.state.systemParam.stuStatus.length) {
        await store.dispatch('systemParam/getSystemDictionary', 'stuStatus')
      }
      let _stuStatusList = this.$store.state.systemParam.stuStatus
      // 有时候需要过滤(filterStuStatusList 只存 paramValue/id),获取两个数组之间的交集
      if (this.filterStuStatusList.length) {
        _stuStatusList = [..._stuStatusList].filter((item) =>
          [...this.filterStuStatusList].some((paramValue) => Number(item.paramValue) === paramValue)
        )
      }
      this.stuStatusList = _stuStatusList
    },
    // 修改学制
    handlePartTimeFlag(partTimeFlag) {
      this.getMajorList(partTimeFlag)
    },
    // 保存初始的query
    setInitQuery() {
      this.initQuery = cloneDeep(this.queryInfo)
    },
    clearList(arr) {
      while (arr.length) arr.pop()
    },
    // 搜索,防抖
    search: throttle(function() {
      Object.keys(this.queryInfo).forEach(key => {
        if (this.queryInfo[key] === '') {
          this.queryInfo[key] = null
        }
      })
      this.$emit('on-search')
    }, 800),
    // 重置
    clear: throttle(function() {
      // 1.先清空list
      // 如果存在同时存在 专业+班级/院系+班级的过滤项, 则清空班级list
      if (this.toolList.includes('class')) {
        if (this.toolList.includes('major') || this.toolList.includes('college')) {
          this.clearList(this.classList)
        }
      }
      // 如果存在同时存在 院系+专业的过滤项, 则清空专业list
      if (this.toolList.includes('major')) {
        // 账号分配了管辖学院后，不清空专业list (存在this.$store.state.login.userInfo.collegeId)
        if (this.toolList.includes('college') && !this.$store.state.login.userInfo.collegeId) {
          this.clearList(this.majorList)
        }
      }
      // 2.再还原queryInfo
      // 先保存 显示条数
      const _pageSize = this.queryInfo.pageSize
      const _initData = this.initQuery
      Object.keys(_initData).forEach(key => {
        if (key === 'collegeId') {
          // 如果该账号分配了 管辖学院，不清空collegeId
          this.queryInfo[key] = this.$store.state.login.userInfo.collegeId || _initData[key]
        } else {
          this.queryInfo[key] = _initData[key]
        }
      })
      this.queryInfo.pageSize = _pageSize
      // this.queryInfo.loadFlag===true时 不请求数据
      if (!this.queryInfo.loadFlag) {
        this.$emit('on-search')
      } else {
        this.$emit('on-clear')
      }
    }, 1000),
    // 判断是否包含该过滤参数
    isIncludes(item) {
      return this.toolList.includes(item)
    },
    // 判断该选项是否禁用
    disabledIncludes(item) {
      return this.disabledTools.includes(item)
    },
    // 判断是否为对象
    isObject(arg) {
      return Object.prototype.toString.call(arg) === '[object object]'
    }
  }
}
</script>

<style lang="scss" scoped>
.view-search-form {
  &.el-form {
    padding: 0;

    .el-form-item {
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>
